.ExampleAdSlot {
  height: 'auto';
  width: '100%';
  display: block;
}

@media (min-width: 1280px) {
  .ExampleAdSlot {
    display: none !important;
  }
}

@media (max-width: 1000px) {
  .ExampleAdSlot {
    width: '100%';
    height: 'auto';
  }
}

@media (max-width: 750px) {
  .ExampleAdSlot {
    width: '100%';
    height: 'auto';
  }
}
