#contactIcons {
  margin: auto;
  justify-content: space-between;
  > div {
    margin: 10px;
    > a {
      > span {
        transition: all 0.3s ease-in-out;
        & :hover {
          transition: all 0.3s ease-in-out;
          color: rgb(31, 143, 134);
        }
        > svg {
          /* border: 1px solid red; */
        }
      }
    }
  }
}

#whatsappIcon {
  & :hover {
    transition: all 0.3s ease-in-out;
    color: rgb(31, 143, 134);
  }
}
