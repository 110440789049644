.accordion {
  color: #000000;
  background: none !important;
  background-color: transparent !important;
}
.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
  color: #000000;
  background: red;
  /* background-color: red; */
  background-color: transparent !important;
}

.ant-collapse-header-text {
  color: aliceblue;
}
.ant-collapse-header {
  display: flex !important;
  align-items: center !important;
}
.ant-collapse-expand-icon > span svg {
  color: aliceblue;
}
.anticon-caret-right,
.ant-collapse-arrow {
  > svg {
    margin: auto auto;
    font-size: 2rem;
  }
}
