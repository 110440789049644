/*-----------------------------
    Portfolio details  
--------------------------------*/
.rwt-portfolio-details {
  .content-left {
    .title {
      font-size: 38px;
      margin-bottom: 33px;
      @media #{$sm-layout} {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }
    .single-list-wrapper {
      margin: -5px;
      .single-list {
        margin: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        & + .single-list {
          border-top: 1px solid var(--color-border);
        }
        label {
          font-size: 20px;
          color: #fff;
          display: inline-block;
          padding-right: 16px;
          margin: 0;
        }
        span {
          font-size: 18px;
          text-align: end;
        }
      }
    }
  }
  .content-right {
    .subtitle {
      font-size: 25px;
      letter-spacing: 0.5px;
      font-weight: 500;
    }
    .description {
      p {
        margin-bottom: 10px;
      }
    }
  }
}

.rwt-portfolio-details {
  .alignwide {
    margin-left: -100px;
    margin-right: -100px;
    max-width: 1400px;
    width: auto;
    @media #{$lg-layout} {
      margin-left: 0;
      margin-right: 0;
    }
    @media #{$md-layout} {
      margin-left: 0;
      margin-right: 0;
    }
    @media #{$sm-layout} {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
