body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button.ant-btn.css-dev-only-do-not-override-1sn0ohr.ant-btn-default,
button.ant-btn.css-dev-only-do-not-override-1sn0ohr.ant-btn-primary {
  display: none;
}

.modal1 {
  background: var(--color-primary);
}
